import kyInstance from '@/services/Ky';
import { getRawMaterials } from '@/api/raw_materials';

const mutations = {
  setRawMaterials(state, payload) {
    state.rawMaterials = payload;
  },
  setSelectedRawMaterial(state, payload) {
    state.selectedRawMaterial = payload;
  },
  setLoadingState(state, payload) {
    state.isLoading = payload;
  },
};

const actions = {
  async getRawMaterials({ commit }, query) {
    commit('setLoadingState', true);
    try {
      const rawMaterials = await getRawMaterials(query);
      commit('setRawMaterials', rawMaterials);
    } catch (error) {
      console.log(error, 'error obteniendo materia prima');
    } finally {
      commit('setLoadingState', false);
    }
  },
  async getRawMaterialById({ commit }, payload) {
    const rawMaterial = await kyInstance.get(`raw-materials/${payload}`).json();
    commit('setSelectedRawMaterial', rawMaterial);
  },
  async createRawMaterial({ dispatch }, payload) {
    try {
      await kyInstance.post('raw-materials', { json: payload });
      await dispatch(
        'createNotification',
        { msg: 'Material Creado', type: 'positive' },
        { root: true },
      );
      await dispatch('getRawMaterials');
    } catch (error) {
      await dispatch(
        'createNotification',
        { msg: 'Error creando material', type: 'negative' },
        { root: true },
      );
      throw error;
    }
  },
  async editRawMaterial({ dispatch }, payload) {
    try {
      await kyInstance.patch(`raw-materials/${payload.id}`, { json: payload });
      await dispatch(
        'createNotification',
        { msg: 'Material Editado', type: 'positive' },
        { root: true },
      );
      await dispatch('getRawMaterials');
    } catch (error) {
      await dispatch(
        'createNotification',
        { msg: 'Error editando material', type: 'negative' },
        { root: true },
      );
    }
  },
  async deleteRawMaterial({ dispatch }, payload) {
    try {
      await kyInstance.delete(`raw-materials/${payload}`);
      await dispatch(
        'createNotification',
        { msg: 'Material Eliminado', type: 'positive' },
        { root: true },
      );
      await dispatch('getRawMaterials');
    } catch (error) {
      await dispatch(
        'createNotification',
        { msg: 'Error eliminando material', type: 'negative' },
        { root: true },
      );
    }
  },
};

const state = {
  selectedRawMaterial: null,
  isLoading: false,
  rawMaterials: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
