<template>
  <q-breadcrumbs>
    <template v-slot:separator>
      <q-icon
        size="25px"
        name="chevron_right"
        color="gray"
      />
    </template>

    <q-breadcrumbs-el :label="props.title"
      class="title fs-16px fs-sm-24px"/>
    <q-breadcrumbs-el :label="props.subtitle" v-if="props.subtitle"
      class="subtitle fs-10px fs-sm-16px"/>
  </q-breadcrumbs>
</template>

<script setup>
const props = defineProps({
  title: String,
  subtitle: String,
});
</script>

<style lang="scss" scoped>
.title {
  font-family: 'Jost', sans-serif;
  // font-size: 24px;
  font-weight: 600;
  color: $title-text;
  // color: $primary;
}
.subtitle {
  font-family: 'Jost', sans-serif;
  // font-size: 16px;
  font-weight: 600;
  color: $primary;
  vertical-align: bottom;
}
</style>
