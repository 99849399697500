<template>
  <BaseCard class="card">
    <q-card-section class="card-header">
      <div class="heading">
        <span class="text-h6">{{ props.title }}</span>
        <slot />
      </div>
    </q-card-section>
    <q-card-section v-if="items && items.length > 0" class="card-list">
      <q-list>
        <ProduccionInfoItem v-for="item of items"
          :key="item.name"
          :item="item">
        </ProduccionInfoItem>
      </q-list>
    </q-card-section>
    <EmptyState height="25vh" v-else/>
    <q-inner-loading v-if="isLoading" showing color="primary" />
  </BaseCard>
</template>

<script setup>
import EmptyState from '@/components/EmptyState.vue';
import BaseCard from './base/BaseCard.vue';
import ProduccionInfoItem from './ProductionInfoItem.vue';

const props = defineProps({
  title: String,
  items: Array,
  isLoading: Boolean,
});
</script>

<style lang="scss" scoped>
.heading{
  display: flex;
  justify-content: space-between;
}
.card-list{
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.card-list::-webkit-scrollbar {
  display: none;
}
.card-header {
  padding-bottom: 0;
}
</style>
