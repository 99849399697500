import ky from 'ky';

const baseUrl = process.env.VUE_APP_BACKEND_URL;

let store;

export const injectStore = (_store) => {
  store = _store;
};

export default ky.create({
  prefixUrl: baseUrl,
  credentials: 'include',
  timeout: 60000,
  hooks: {
    afterResponse: [
      async (request, options, response) => {
        if (!response.ok) {
          // Authentication error
          if (response.status === 401 && store) {
            store.commit('auth/setUser', null);
          }
          // TODO: Handle error user facing notifications
        }
      },
    ],
  },
});
