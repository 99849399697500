<template>
  <div class="empty" :style="{ 'height': props.height }">
    <div>
      EMPTY
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  height: String,
});
</script>

<style lang="scss" scoped>
.empty{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
