/* eslint-disable no-param-reassign */
import {
  getSuppliers,
} from '@/api/suppliers';

const mutations = {
  setSuppliers(state, payload) {
    state.suppliers = payload;
  },
};

const actions = {
  async getSuppliers({ commit }) {
    try {
      const suppliersList = await getSuppliers();
      commit('setSuppliers', suppliersList);
    } catch (error) {
      console.log(error, 'error obteniendo proveedores');
    }
  },
};

const state = {
  suppliers: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
