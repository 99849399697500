/* eslint-disable arrow-body-style */
import convertToURLSearchParams from '@/helpers/urlParams';
import kyInstance from '@/services/Ky';

/**
 * Retrieves colors based on the provided query.
 * @param {Object} query - The query parameters for filtering colors.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of colors.
 */
const getColors = async (query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get('colors', {
    searchParams,
  }).json();
};

/**
 * Retrieves a specific color by its ID.
 * @param {string} id - The ID of the color to retrieve.
 * @param {Object} query - The query parameters for the color retrieval.
 * @returns {Promise<Object>} - A promise that resolves to the retrieved color object.
 */
const getColor = async (id, query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get(`colors/${id}`, { searchParams }).json();
};

/**
 * Creates a new color.
 * @param {Object} body - The request body containing the color data.
 * @returns {Promise<void>} - A promise that resolves when the color is created successfully.
 */
const createColor = async (body) => {
  await kyInstance.post('colors', { json: body });
};

/**
 * Edits an existing color.
 * @param {string} id - The ID of the color to be edited.
 * @param {Object} body - The request body containing the updated color data.
 * @returns {Promise<void>} - A promise that resolves when the color is edited successfully.
 */
const editColor = async (id, body) => {
  await kyInstance.patch(`colors/${id}`, { json: body });
};

/**
 * Deletes a color.
 * @param {string} id - The ID of the color to be deleted.
 * @returns {Promise<void>} - A promise that resolves when the color is deleted successfully.
 */
const deleteColor = async (id) => {
  await kyInstance.delete(`colors/${id}`);
};

export {
  getColors,
  getColor,
  createColor,
  editColor,
  deleteColor,
};
