<template>
  <slot v-if="hasPermission" />
</template>

<script setup>
import { watch, ref, onMounted } from 'vue';
import { mapState } from '@/helpers/stateLib';

const { user } = mapState('auth');

const props = defineProps({
  permission: String,
});

const hasPermission = ref(false);

const checkPermission = () => {
  const role = user.value ? user.value.role : null;

  if (!role) {
    hasPermission.value = false;
    return;
  }

  hasPermission.value = role.permissions.some((p) => p.name === props.permission && p.permission_value);
};

watch(() => user.value, () => {
  checkPermission();
});

onMounted(() => {
  checkPermission();
});
</script>
