import { createStore } from 'vuex';
import Notify from 'quasar/src/plugins/Notify.js';;

import auth from './modules/auth';
import areas from './modules/areas';
import products from './modules/products';
import orders from './modules/orders';
import packages from './modules/packages';
import permissions from './modules/permissions';
import cardreader from './modules/cardreader';
import users from './modules/users';
import reports from './modules/reports';
import clients from './modules/clients';
import warehouse from './modules/warehouse';
import rawmaterials from './modules/rawmaterials';
import suppliers from './modules/suppliers';
import materialsdeliveries from './modules/rawmaterialsdeliveries';
import machines from './modules/machines';
import colors from './modules/colors';
import materials from './modules/materials';

Notify.setDefaults({
  position: 'top',
  timeout: 2500,
  textColor: 'white',
  actions: [{ icon: 'close', color: 'white' }],
});

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    createNotification(_, payload) {
      Notify.create({ message: payload.msg, type: payload.type });
    },
  },
  modules: {
    auth,
    areas,
    products,
    orders,
    packages,
    permissions,
    cardreader,
    users,
    reports,
    clients,
    warehouse,
    rawmaterials,
    suppliers,
    materialsdeliveries,
    machines,
    colors,
    materials,
  },
});
