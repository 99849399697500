/* eslint-disable no-param-reassign */
import {
  getWarehouseLocations,
} from '@/api/warehouse_locations';

const mutations = {
  setWarehouseLocations(state, payload) {
    state.warehouseLocations = payload;
  },
};

const actions = {
  async getWarehouseLocations({ commit }) {
    try {
      const warehouseLocations = await getWarehouseLocations();
      commit('setWarehouseLocations', warehouseLocations);
    } catch (error) {
      console.log(error, 'error obteniendo ubicaciones de almacén');
    }
  },
};

const state = {
  warehouseLocations: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
