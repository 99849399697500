<template>
  <q-card class="card" flat bordered>
    <slot />
  </q-card>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.card{
  border-radius: 12px;
  font-family: 'Jost', sans-serif;
}
</style>
