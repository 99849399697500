import kyInstance from '@/services/Ky';

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
};

const actions = {
  async getLoggedInUser(store) {
    try {
      const user = await kyInstance.get('auth/user').json();
      store.commit('setUser', user);
    } catch (error) {
      console.log(error, 'error');
    }
  },
  async login({ commit }, payload) {
    let authorized = false;
    try {
      const user = await kyInstance.post('auth/login', { json: payload }).json();
      commit('setUser', user);
      authorized = true;
    } catch (error) {
      if (error.response.status === 401) {
        authorized = false;
      }
      throw error;
    }
    return authorized;
  },
  async forgotPassword({ dispatch }, payload) {
    try {
      await kyInstance.post('auth/forgot-password', { json: payload });
      await dispatch(
        'createNotification',
        { msg: 'Correo enviado', type: 'positive' },
        { root: true },
      );
    } catch (error) {
      await dispatch(
        'createNotification',
        { msg: 'Error enviando correo', type: 'negative' },
        { root: true },
      );
    }
  },
  async resetPassword({ dispatch }, payload) {
    try {
      await kyInstance.post('auth/reset-password', { json: payload });
      await dispatch(
        'createNotification',
        { msg: 'Contraseña actualizada', type: 'positive' },
        { root: true },
      );
    } catch (error) {
      await dispatch(
        'createNotification',
        { msg: 'Error actualizando contraseña', type: 'negative' },
        { root: true },
      );
    }
  },
  async logout() {
    await kyInstance.post('auth/logout');
  },
};

const getters = {};

const state = {
  user: null,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
