import { getMaterials } from '@/api/materials';

const mutations = {
  setMaterials(state, payload) {
    state.materials = payload;
  },
  setLoadingState(state, payload) {
    state.isLoading = payload;
  },
};

const actions = {
  async getMaterials({ commit }, query) {
    commit('setLoadingState', true);
    try {
      const materials = await getMaterials(query);
      commit('setMaterials', materials);
    } catch (error) {
      console.log(error, 'error obteniendo materiales');
    } finally {
      commit('setLoadingState', false);
    }
  },
};

const state = {
  materials: [],
  isLoading: false,
};

export default {
  namespaced: true,
  mutations,
  actions,
  state,
};
