<template>
<q-layout view="lHh LpR fFf">
  <!-- <q-layout view="lHh lpR lff"> -->
  <q-page-container id="page-c"
    style="background-color:#F8F8F8; min-height:100vh;">
    <router-view />
  </q-page-container>
</q-layout>
</template>

<script setup>
import { onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { mapState, mapActions } from '@/helpers/stateLib';

const router = useRouter();
const route = useRoute();

const { getLoggedInUser } = mapActions('auth');
const { user } = mapState('auth');

const routeValidation = async (path) => {
  const guestRoutes = ['/login', '/forgot-password', '/new-password'];
  const isInGuestRoutes = guestRoutes.includes(path);

  if (user.value == null && !isInGuestRoutes) {
    await router.push({ name: 'Login' });
  } else if (user.value != null && isInGuestRoutes) {
    await router.push({ name: 'home' });
  }
};

watch(
  () => user.value,
  (value, oldValue) => {
    if (oldValue != null && value == null) {
      router.push({ name: 'Login' });
    }
  },
);

onMounted(async () => {
  await getLoggedInUser();
  await router.isReady();
  await routeValidation(route.path);
});
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700&display=swap');
</style>
