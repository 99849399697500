import {
  getRoles,
} from '@/api/roles';

import {
  getPermissionsList,
} from '@/api/permissions';

const mutations = {
  setPermissionsList(state, payload) {
    state.permissionsList = payload;
  },
  setRoles(state, payload) {
    state.roles = payload;
  },
  setCurrentRole(state, payload) {
    state.currentRole = payload;
  },
  deleteTemplate(state, payload) {
    state.templates.splice(payload.id, 1);
  },
};

const actions = {
  async getPermissionsList({ commit }) {
    try {
      const permissions = await getPermissionsList();
      commit('setPermissionsList', permissions);
    } catch (error) {
      console.log(error, 'error obteniendo permisos');
    }
  },

  async getRoles({ commit }) {
    try {
      const query = { 'expand[]': 'permissions' };
      const templates = await getRoles(query);
      commit('setRoles', templates);
    } catch (error) {
      console.log(error, 'error obteniendo productos');
    }
  },
};

const getters = {};

const state = {
  permissionsList: [],
  roles: [],
  currentRole: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
