/* eslint-disable no-param-reassign */
import {
  getAreas,
} from '@/api/areas';

const mutations = {
  setAreas(state, payload) {
    state.areasArray = payload;
  },
};

const actions = {
  async getAreas({ commit, dispatch }) {
    try {
      const areas = await getAreas();
      commit('setAreas', areas);
    } catch (error) {
      await dispatch(
        'createNotification',
        { msg: 'Error obteniendo áreas', type: 'negative' },
        { root: true },
      );
    }
  },
};

const state = {
  areasArray: [],
  selectedMachinesByArea: [],
  selectedMachine: null,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
