import kyInstance from '@/services/Ky';

const mutations = {
  setOrders(state, payload) {
    state.ordersArray = payload;
  },
  setOrdersHistory(state, payload) {
    state.ordersHistoryArray = payload;
  },
  setSelectedOrder(state, payload) {
    state.selectedOrder = payload;
  },
  setWarehouseOrders(state, payload) {
    state.warehouseOrdersArray = payload;
  },
};

const actions = {
  // Get all production orders. Pagination, sorting and filtering are optional.
  async getOrders({ commit }, payload) {
    const searchParams = new URLSearchParams();

    if (payload && payload.pagination) {
      searchParams.append('page', payload.pagination.page);
      searchParams.append('limit', payload.pagination.rowsPerPage);
    }

    searchParams.append('status[]', 'pending');
    searchParams.append('status[]', 'in_progress');
    searchParams.append('status[]', 'paused');

    searchParams.append('expand[]', 'machines.area');
    searchParams.append('expand[]', 'product');

    // direction is represented with + or - in the API
    if (payload && payload.sorting) {
      const direction = payload.sorting.descending === 'desc' ? '-' : '+';
      searchParams.append('sort_by', `${direction}${payload.sorting.sortBy}`);
    }

    if (payload && payload.filter) {
      searchParams.append('user_set_id', payload.filter);
    }

    try {
      const response = await kyInstance.get('production-orders', {
        searchParams,
      }).json();

      if (payload) {
        const { data: orders, meta } = response;
        commit('setOrders', orders);
        return meta;
      }

      commit('setOrders', response);
      return null;
    } catch (error) {
      // TODO: Send error message to the user
      console.log(error, 'error obteniendo productos');
      return null;
    }
  },

  // Get all warehouse orders. Pagination, sorting and filtering are optional.
  async getWarehouseOrders({ commit }, payload) {
    const searchParams = new URLSearchParams();

    if (payload && payload.pagination) {
      searchParams.append('page', payload.pagination.page);
      searchParams.append('limit', payload.pagination.rowsPerPage);
    }

    searchParams.append('status[]', 'pending');
    searchParams.append('status[]', 'in_progress');
    searchParams.append('status[]', 'in_warehouse');

    searchParams.append('expand[]', 'machines.area');
    searchParams.append('expand[]', 'product');

    // direction is represented with + or - in the API
    if (payload && payload.sorting) {
      const direction = payload.sorting.descending === 'desc' ? '-' : '+';
      searchParams.append('sort_by', `${direction}${payload.sorting.sortBy}`);
    }

    if (payload && payload.filter) {
      searchParams.append('user_set_id', payload.filter);
    }

    try {
      const response = await kyInstance.get('production-orders', {
        searchParams,
      }).json();

      if (payload) {
        const { data: orders, meta } = response;
        commit('setWarehouseOrders', orders);
        return meta;
      }
      commit('setWarehouseOrders', response);
      return null;
    } catch (error) {
      console.log(error, 'error obteniendo productos');
      return null;
    }
  },

  async getOrdersHistory({ commit }, payload) {
    const searchParams = new URLSearchParams();

    if (payload && payload.pagination) {
      searchParams.append('page', payload.pagination.page);
      searchParams.append('limit', payload.pagination.rowsPerPage);
    }

    searchParams.append('status[]', 'completed');
    searchParams.append('status[]', 'cancelled');

    searchParams.append('expand[]', 'machines.area');
    searchParams.append('expand[]', 'product');

    // direction is represented with + or - in the API
    if (payload && payload.sorting) {
      const direction = payload.sorting.descending === 'desc' ? '-' : '+';
      searchParams.append('sort_by', `${direction}${payload.sorting.sortBy}`);
    }

    if (payload && payload.filter) {
      searchParams.append('user_set_id', payload.filter);
    }

    try {
      const response = await kyInstance.get('production-orders', {
        searchParams,
      }).json();

      if (payload) {
        const { data: orders, meta } = response;
        commit('setOrdersHistory', orders);
        return meta;
      }

      commit('setOrdersHistory', response);
      return null;
    } catch (error) {
      // TODO: Send error message to the user
      console.log(error, 'error obteniendo productos');
      return null;
    }
  },

  async getOrderById({ commit }, payload) {
    try {
      const searchParams = new URLSearchParams();
      searchParams.append('expand[]', 'client');
      searchParams.append('expand[]', 'machines');
      const order = await kyInstance.get(`production-orders/${payload.id}`, {
        searchParams,
      }).json();
      commit('setSelectedOrder', order);

      return order;
    } catch (error) {
      console.log(error, 'error obteniendo productos');
      return null;
    }
  },

  async createOrder({ dispatch }, payload) {
    await kyInstance.post('production-orders', { json: payload });
    await dispatch(
      'createNotification',
      { msg: 'Orden Creada', type: 'positive' },
      { root: true },
    );
  },

  async editOrder({ dispatch }, payload) {
    await kyInstance.patch(`production-orders/${payload.id}`, { json: payload });
    await dispatch(
      'createNotification',
      { msg: 'Orden Editada', type: 'positive' },
      { root: true },
    );
  },

  async deleteOrder({ dispatch }, payload) {
    try {
      await kyInstance.delete(`production-orders/${payload.id}`);
      await dispatch(
        'createNotification',
        { msg: 'Orden Eliminada', type: 'positive' },
        { root: true },
      );
    } catch (error) {
      console.log(error, 'error creando producto');
    }
  },

  async checkoutPackagePieces(state, payload) {
    await kyInstance.post(`packages/${payload.orderId}`, { json: payload });
  },
};

const getters = {};

const state = {
  ordersArray: [],
  warehouseOrdersArray: [],
  ordersHistoryArray: [],
  selectedOrder: null,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
