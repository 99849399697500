/* eslint-disable arrow-body-style */
import convertToURLSearchParams from '@/helpers/urlParams';
import kyInstance from '@/services/Ky';

/**
 * Retrieves users based on the provided query.
 * @param {Object} query - The query parameters for filtering users.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of users.
 */
const getUsers = async (query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get('users', {
    searchParams,
  }).json();
};

/**
 * Retrieves a specific user by its ID.
 * @param {string} id - The ID of the user to retrieve.
 * @param {Object} query - The query parameters for the user retrieval.
 * @returns {Promise<Object>} - A promise that resolves to the retrieved user object.
 */
const getUser = async (id, query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get(`users/${id}`, { searchParams }).json();
};

/**
 * Creates a new user.
 * @param {Object} body - The request body containing the user data.
 * @returns {Promise<void>} - A promise that resolves when the create request is successful.
 */
const createUser = async (body) => {
  await kyInstance.post('users', { json: body });
};

/**
 * Edits an existing user.
 * @param {string} id - The ID of the user to be edited.
 * @param {Object} body - The request body containing the updated user data.
 * @returns {Promise<void>} - A promise that resolves when the edit request is successful.
 */
const editUser = async (id, body) => {
  await kyInstance.patch(`users/${id}`, { json: body });
};

/**
 * Deletes a user.
 * @param {string} id - The ID of the user to be deleted.
 * @returns {Promise<void>} - A promise that resolves when the delete request is successful.
 */
const deleteUser = async (id) => {
  await kyInstance.delete(`users/${id}`);
};

export {
  getUsers,
  getUser,
  createUser,
  editUser,
  deleteUser,
};
