<template>
  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 0.199402H13.165V2.55234H0V0.199402ZM0 4.90528H13.165V7.25822H0V4.90528ZM0
        9.61116H8.37772V11.9641H0V9.61116ZM18.3101 6.42529L13.1638 11.4735L11.6175 9.9547L9.9252
        11.6194L13.1638 14.8006L20 8.09118L18.3101 6.42529Z"
      :class="props.active ? 'active' : 'not-active'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 0.199402H13.165V2.55234H0V0.199402ZM0 4.90528H13.165V7.25822H0V4.90528ZM0
        9.61116H8.37772V11.9641H0V9.61116ZM18.3101 6.42529L13.1638 11.4735L11.6175 9.9547L9.9252
        11.6194L13.1638 14.8006L20 8.09118L18.3101 6.42529Z"
      :class="props.active ? 'active' : 'not-active'"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  color: String,
  active: Boolean,
});
</script>

<style lang="scss" scoped>
.active{
  fill: $primary;
}
.not-active{
  fill: $text-a;
}
</style>
