import kyInstance from '@/services/Ky';

const mutations = {
  setRawMaterialsDeliveries(state, payload) {
    state.rawMaterialsDeliveries = payload;
  },
  setRawMaterialDeliveryItems(state, payload) {
    state.rawMaterialDeliveryItems = payload;
  },
  setRawMaterialPallets(state, payload) {
    state.rawMaterialPallets = payload;
  },
};

const actions = {
  async getRawMaterialsDeliveries({ commit, payload }) {
    const searchParams = new URLSearchParams();

    if (payload && payload.pagination) {
      searchParams.append('page', payload.pagination.page);
      searchParams.append('limit', payload.pagination.rowsPerPage);
    }

    searchParams.append('expand[]', 'supplier');

    if (payload && payload.sorting) {
      const direction = payload.sorting.descending === 'desc' ? '-' : '+';
      searchParams.append('sort_by', `${direction}${payload.sorting.sortBy}`);
    }

    if (payload && payload.filter) {
      searchParams.append('user_set_id', payload.filter);
    }

    try {
      const response = await kyInstance.get('raw-materials-deliveries', {
        searchParams,
      }).json();

      if (payload) {
        const { data: rawMaterialsDeliveries, meta } = response;
        commit('setRawMaterialsDeliveries', rawMaterialsDeliveries);
        return meta;
      }

      commit('setRawMaterialsDeliveries', response);
      return null;
    } catch (error) {
      console.log(error, 'error obteniendo productos');
      return null;
    }
  },
  async createRawMaterialDelivery({ dispatch }, payload) {
    try {
      await kyInstance.post('raw-materials-deliveries', { json: payload });
      dispatch('getRawMaterialsDeliveries');
    } catch (error) {
      console.log(error, 'error creando proveedor');
    }
  },
  async updateRawMaterialDelivery({ dispatch }, payload) {
    try {
      await kyInstance.patch(`raw-materials-deliveries/${payload.id}`, { json: payload.data });
      dispatch('getRawMaterialsDeliveries');
    } catch (error) {
      console.log(error, 'error actualizando proveedor');
    }
  },
  async deleteRawMaterialDelivery({ dispatch }, payload) {
    try {
      await kyInstance.delete(`raw-materials-deliveries/${payload.id}`);
      dispatch('getRawMaterialsDeliveries');
    } catch (error) {
      console.log(error, 'error eliminando proveedor');
    }
  },
  async getRawMaterialDelivery(_, payload) {
    try {
      const response = await kyInstance.get(`raw-materials-deliveries/${payload}`).json();

      return response;
    } catch (error) {
      console.log(error, 'error obteniendo proveedor');
      return null;
    }
  },

  async getRawMaterialDeliveryItems({ commit }, payload) {
    try {
      const response = await kyInstance.get(`raw-materials-deliveries/${payload.id}/items`).json();

      commit('setRawMaterialDeliveryItems', response);
    } catch (error) {
      console.log(error, 'error obteniendo proveedor');
    }
  },
  async getRawMaterialPalletsByDeliveryId({ commit }, payload) {
    try {
      const searchParams = new URLSearchParams();
      const response = await kyInstance.get(`raw-materials-deliveries/${payload.raw_materials_delivery_id}/pallets`, { searchParams }).json();
      commit('setRawMaterialPallets', response);
    } catch (error) {
      console.log(error, 'error obteniendo proveedor');
    }
  },
  async createRawMaterialDeliveryItem({ dispatch }, payload) {
    try {
      await kyInstance.post(`raw-materials-deliveries/${payload.id}/items`, { json: payload.data });
      dispatch('getRawMaterialDeliveryItems', { id: payload.id });
    } catch (error) {
      console.log(error, 'error creando proveedor');
    }
  },
  async deleteRawMaterialDeliveryItem({ dispatch }, payload) {
    try {
      await kyInstance.delete(`raw-materials-deliveries/${payload.deliveryId}/items/${payload.itemId}`);
      dispatch('getRawMaterialDeliveryItems', { id: payload.deliveryId });
    } catch (error) {
      console.log(error, 'error eliminando proveedor');
    }
  },
};

const getters = {
  approvedPallets(state) {
    return state.rawMaterialPallets.filter((pallet) => pallet.is_approved);
  },
  rejectedPallets(state) {
    return state.rawMaterialPallets.filter((pallet) => !pallet.is_approved && pallet.inspections !== null);
  },
};

const state = {
  rawMaterialsDeliveries: [],
  rawMaterialDeliveryItems: [],
  rawMaterialPallets: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
