<template>
  <BaseCard class="card">
    <q-card-section class="card-header">
      <div class="text-h6">{{ props.title }}</div>
    </q-card-section>
    <q-card-section v-if="props.items && props.items.length > 0" class="card-list">
      <q-list>
        <q-item
          v-for="item of props.items"
          :key="item">
          <q-item-section avatar>
            <q-avatar size="30px"
              font-size="18px"
              color="negative"
              icon="error_outline" />
          </q-item-section>

          <q-item-section class="name">
            <q-item-label class="item-name">{{ item.order }}<span class="subitem">{{ item.name }}</span></q-item-label>
          </q-item-section>

          <q-item-section side>
            <span @click="viewProductionOrder(item)" class="view-span">
              Ver orden <span class="hide-mobile">de produccion</span>
            </span>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card-section>
    <EmptyState height="25vh" v-else/>
    <q-inner-loading v-if="isLoading" showing color="primary" />
  </BaseCard>
</template>

<script setup>
import { useRouter } from 'vue-router';
import EmptyState from '@/components/EmptyState.vue';
import BaseCard from '@/components/base/BaseCard.vue';

const router = useRouter();

const props = defineProps({
  title: String,
  items: Array,
  isLoading: Boolean,
});

const viewProductionOrder = (item) => {
  router.push({
    name: 'ProductionOrder',
    params: { id: item.orderId },
  });
};
</script>

<style lang="scss" scoped>
.view-span{
  cursor: pointer;
  color: $primary;
  font-size: 14px;
  font-family: 'Jost', sans-serif;
}
.item-name{
  font-size: 16px;
  font-family: 'Jost', sans-serif;
}
.item-order{
  font-size: 15px;
  font-family: 'Jost', sans-serif;
}
.bg-negative {
  background: rgba($negative, 0.4) !important;
  color: $negative;
  vertical-align: super;
}
.card-list{
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.card-list::-webkit-scrollbar {
  display: none;
}
.card-header{
  padding-bottom: 0;
}
.subitem {
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #9E9E9E;
  margin-left: 20px;
}
@media only screen and (max-width: 700px) {
  .view-span{
    max-width: fit-content;
  }
  .name {
    display: flex;
    flex-direction: column;
  }
  .subitem {
    display: block;
    margin-left: 0;
    margin-top: 5px;
  }
  .hide-mobile {
    display: none;
  }
}
</style>
