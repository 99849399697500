/* eslint-disable arrow-body-style */
import convertToURLSearchParams from '@/helpers/urlParams';
import kyInstance from '@/services/Ky';

/**
 * Retrieves materials based on the provided query.
 * @param {Object} query - The query parameters for filtering materials.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of materials.
 */
const getMaterials = async (query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get('materials', {
    searchParams,
  }).json();
};

/**
 * Retrieves a specific material by its ID.
 * @param {string} id - The ID of the material to retrieve.
 * @param {Object} query - The query parameters for the material retrieval.
 * @returns {Promise<Object>} - A promise that resolves to the retrieved material object.
 */
const getMaterial = async (id, query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get(`materials/${id}`, { searchParams }).json();
};

/**
 * Creates a new material.
 * @param {Object} body - The request body containing the material data.
 * @returns {Promise<void>} - A promise that resolves when the material is created successfully.
 */
const createMaterial = async (body) => {
  await kyInstance.post('materials', { json: body });
};

/**
 * Edits an existing material.
 * @param {string} id - The ID of the material to be edited.
 * @param {Object} body - The request body containing the updated material data.
 * @returns {Promise<void>} - A promise that resolves when the material is edited successfully.
 */
const editMaterial = async (id, body) => {
  await kyInstance.patch(`materials/${id}`, { json: body });
};

/**
 * Deletes a material.
 * @param {string} id - The ID of the material to be deleted.
 * @returns {Promise<void>} - A promise that resolves when the material is deleted successfully.
 */
const deleteMaterial = async (id) => {
  await kyInstance.delete(`materials/${id}`);
};

export {
  getMaterials,
  getMaterial,
  createMaterial,
  editMaterial,
  deleteMaterial,
};
