import './styles/quasar.scss';
import '@quasar/extras/material-icons/material-icons.css';
import langEs from 'quasar/lang/es';
import Notify from 'quasar/src/plugins/Notify.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: {
  },
  lang: langEs,
  plugins: {
    Notify,
  },
};
