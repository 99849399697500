import kyInstance from '@/services/Ky';

const mutations = {
  resetState(state) {
    state.user = null;
  },
  setUser(state, payload) {
    state.user = payload;
  },
};

const actions = {
  async getCurrentUser({ state, commit }, cardCode) {
    if (!state.user || state.user.code !== cardCode) {
      const user = await kyInstance.get(`card-users/${cardCode}`).json();
      commit('setUser', user);
    }
  },
};

const state = {
  user: null,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
