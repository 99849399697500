<template>
  <Header title="Inicio"></Header>
  <div class="home">
    <div class="row alert-row q-mb-md">
      <div class="col-sm-4 col-12">
        <BaseCard class="q-pa-md q-mb-md">
          <div class="q-mb-sm text-subtitle1">Filtrar por área</div>
          <q-select
            outlined
            v-model="selectedFilterAreaId"
            :options="options"
            :dense="true"
            placeholder="Standard" />
        </BaseCard>
        <AlertNotificationCard
          :machineAlerts="unusedMachines.length"
          :productionAlerts="alertItems.length"
          :isLoading="isLoading" />
      </div>
      <div class="col-sm-8 col-12 alert-info">
        <AlertInfoCard
          class="right-side"
          title="Producción por debajo de lo esperado"
          :items="alertItems"
          :isLoading="isLoading" />
      </div>
    </div>
    <div class="row bottom-row">
      <div class="col-sm-6 col-12">
        <ProductionInfoCard
          class="left-side"
          title="Produccion en curso"
          :items="onCourseItems"
          :isLoading="isLoading" />
      </div>
      <div class="col-sm-6 col-12">
        <ProductionInfoCard
          class="right-side production-info"
          title="Produccion de la jornada"
          :items="dayItems"
          :isLoading="isLoading">
          <span class="link"
            @click="$router.push({ name: 'DailyReport'})">Descargar reporte</span>
        </ProductionInfoCard>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  onMounted,
  computed,
  ref,
  watch,
} from 'vue';
import { DateTime } from 'luxon';
import { mapState, mapActions } from '@/helpers/stateLib';
import Header from '@/components/Header.vue';
import BaseCard from '@/components/base/BaseCard.vue';
import ProductionInfoCard from '@/components/ProductionInfoCard.vue';
import AlertInfoCard from '@/components/AlertInfoCard.vue';
import AlertNotificationCard from '@/components/AlertNotificationCard.vue';

const { getOrders } = mapActions('orders');
const { ordersArray } = mapState('orders');
const { getCurrentShiftProduction } = mapActions('reports');
const { currentShiftProduction } = mapState('reports');
const { getMachines } = mapActions('machines');
const { machinesArray } = mapState('machines');

const selectedFilterAreaId = ref({
  label: 'Ensamble',
  value: 4,
});
const options = ref([
  {
    label: 'Inyección',
    value: 1,
  },
  {
    label: 'Extrusión',
    value: 2,
  },
  {
    label: 'Soplado',
    value: 3,
  },
  {
    label: 'Ensamble',
    value: 4,
  },
  {
    label: 'Todos',
    value: null,
  },
]);

const isLoading = ref(false);

const alertItems = computed(() => {
  if (ordersArray.value && ordersArray.value.length > 0) {
    return ordersArray.value.filter((order) => {
      if (selectedFilterAreaId.value?.value && order.product.area_id !== selectedFilterAreaId.value.value) {
        return false;
      }
      const {
        qty_pieces_to_produce: totalPieces,
        produced_pieces: producedPieces,
        start_date: startDate,
        end_date: endDate,
        alert_percentage: alertPercentage,
      } = order;
      const start = DateTime.fromISO(startDate);
      const end = DateTime.fromISO(endDate);

      const totalDiffInMinutes = end.diff(start, 'minutes').toObject().minutes;
      const currentDiffInMinutes = DateTime.local().diff(start, 'minutes').toObject().minutes;
      const expectedPieces = Math.floor(
        (currentDiffInMinutes / totalDiffInMinutes) * totalPieces * (alertPercentage / 100),
      );

      return producedPieces < expectedPieces;
    }).map((order) => ({
      name: `${order.user_set_id}`,
      orderId: order.id,
      order: `${order.product.name}`,
    }));
  }
  return [];
});

const unusedMachines = computed(() => {
  if (machinesArray.value.length > 0) {
    return machinesArray.value.filter((machine) => {
      if (selectedFilterAreaId.value?.value && machine.area_id !== selectedFilterAreaId.value.value) {
        return false;
      }
      return machine.production_order === null;
    });
  }
  return [];
});

const onCourseItems = computed(() => {
  if (machinesArray.value.length > 0) {
    const items = machinesArray.value.reduce((result, machine) => {
      const { production_order: productionOrder } = machine;
      if ((selectedFilterAreaId.value?.value && machine.area_id !== selectedFilterAreaId.value.value)) {
        return result;
      }
      result.push({
        img: '@/assets/production-item.png',
        name: machine.name,
        order: productionOrder
          ? `${productionOrder.user_set_id}`
          : 'Sin asignacion',
      });
      return result;
    }, []);
    return items;
  }
  return [];
});

const dayItems = computed(() => {
  if (currentShiftProduction.value.length > 0) {
    const items = currentShiftProduction.value.map((item) => {
      const { production_order: productionOrder } = item;
      return {
        img: '@/assets/production-item.png',
        name: productionOrder.product?.name,
        order: productionOrder
          ? `${productionOrder.user_set_id}`
          : 'Sin asignacion',
        pieces: item.pieces,
      };
    });
    return items;
  }
  return [];
});

watch(selectedFilterAreaId, async () => {
  await getCurrentShiftProduction({
    currentDate: DateTime.local().toISODate(),
    areaId: selectedFilterAreaId.value.value,
  });
}, { immediate: true });

onMounted(async () => {
  isLoading.value = true;
  await getOrders();
  await getMachines();
  isLoading.value = false;
});
</script>

<style lang="scss" scoped>
.home {
  padding: 20px 25px;
  height: 88vh;
}
.bottom-row{
  height: 50%;
}
.bottom-row .card{
  height: 40vh;
  overflow: scroll;
}
.alert-info .card{
  height: 100%;
  max-height: 43vh;
  overflow: scroll;
}
.right-side{
  margin-left: 15px;
}
@media only screen and (max-width: 600px) {
  .right-side{
    margin-left: 0px;
  }
  .left-side{
    margin-right: 0px;
  }
  .production-info{
    margin-top: 20px;
  }
}
.link{
  cursor: pointer;
  font-family: 'Jost';
  font-weight: 400;
  font-size: 14px;
  color: $primary;
}
</style>
