<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 8.5H7C7.553 8.5 8 8.052 8 7.5V1.5C8 0.948 7.553 0.5 7 0.5H1C0.447
      0.5 0 0.948 0 1.5V7.5C0 8.052 0.447 8.5 1 8.5ZM2 2.5H6V6.5H2V2.5ZM17
      0.5H11C10.447 0.5 10 0.948 10 1.5V7.5C10 8.052 10.447 8.5 11 8.5H17C17.553
      8.5 18 8.052 18 7.5V1.5C18 0.948 17.553 0.5 17 0.5ZM16 6.5H12V2.5H16V6.5ZM7
      18.5C7.553 18.5 8 18.053 8 17.5V11.5C8 10.947 7.553 10.5 7 10.5H1C0.447 10.5 0
      10.947 0 11.5V17.5C0 18.053 0.447 18.5 1 18.5H7ZM2 12.5H6V16.5H2V12.5ZM15
      11.5H13V13.5H11V15.5H13V17.5H15V15.5H17V13.5H15V11.5Z"
      :class="props.active ? 'active' : 'not-active'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 8.5H7C7.553 8.5 8 8.052 8 7.5V1.5C8 0.948 7.553 0.5 7 0.5H1C0.447
      0.5 0 0.948 0 1.5V7.5C0 8.052 0.447 8.5 1 8.5ZM2 2.5H6V6.5H2V2.5ZM17
      0.5H11C10.447 0.5 10 0.948 10 1.5V7.5C10 8.052 10.447 8.5 11 8.5H17C17.553
      8.5 18 8.052 18 7.5V1.5C18 0.948 17.553 0.5 17 0.5ZM16 6.5H12V2.5H16V6.5ZM7
      18.5C7.553 18.5 8 18.053 8 17.5V11.5C8 10.947 7.553 10.5 7 10.5H1C0.447 10.5
      0 10.947 0 11.5V17.5C0 18.053 0.447 18.5 1 18.5H7ZM2 12.5H6V16.5H2V12.5ZM15
      11.5H13V13.5H11V15.5H13V17.5H15V15.5H17V13.5H15V11.5Z"
      :class="props.active ? 'active' : 'not-active'"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  color: String,
  active: Boolean,
});
</script>

<style lang="scss" scoped>
.active{
  fill: $primary;
}
.not-active{
  fill: $text-a;
}
</style>
