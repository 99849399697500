/* eslint-disable arrow-body-style */
import convertToURLSearchParams from '@/helpers/urlParams';
import kyInstance from '@/services/Ky';

/**
 * Retrieves card reader users based on the provided query.
 * @param {Object} query - The query parameters for filtering card reader users.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of card reader users.
 */
const getCardReaderUsers = async (query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get('card-users', {
    searchParams,
  }).json();
};

/**
 * Retrieves a specific card reader user by their ID.
 * @param {string} id - The ID of the card reader user to retrieve.
 * @param {Object} query - The query parameters for the card reader user retrieval.
 * @returns {Promise<Object>} - A promise that resolves to the retrieved card reader user object.
 */
const getCardReaderUser = async (id, query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get(`card-users/${id}`, { searchParams }).json();
};

/**
 * Creates a new card reader user.
 * @param {Object} body - The request body containing the card reader user data.
 * @returns {Promise<void>} - A promise that resolves when the card reader user is created successfully.
 */
const createCardReaderUser = async (body) => {
  await kyInstance.post('card-users', { json: body });
};

/**
 * Edits an existing card reader user.
 * @param {string} id - The ID of the card reader user to be edited.
 * @param {Object} body - The request body containing the updated card reader user data.
 * @returns {Promise<void>} - A promise that resolves when the card reader user is edited successfully.
 */
const editCardReaderUser = async (id, body) => {
  await kyInstance.patch(`card-users/${id}`, { json: body });
};

/**
 * Deletes a card reader user.
 * @param {string} id - The ID of the card reader user to be deleted.
 * @returns {Promise<void>} - A promise that resolves when the card reader user is deleted successfully.
 */
const deleteCardReaderUser = async (id) => {
  await kyInstance.delete(`card-users/${id}`);
};

export {
  getCardReaderUsers,
  getCardReaderUser,
  createCardReaderUser,
  editCardReaderUser,
  deleteCardReaderUser,
};
