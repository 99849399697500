/* eslint-disable no-param-reassign */
import kyInstance from '@/services/Ky';
import {
  getMachines,
} from '@/api/machines';

const mutations = {
  setMachines(state, payload) {
    state.machinesArray = payload;
  },
  addMachine(state, payload) {
    state.machinesArray.push(payload);
  },
  deleteMachine(state, payload) {
    state.machinesArray.splice(payload.id, 1);
  },
  setSelectedMachine(state, payload) {
    state.selectedMachine = payload;
  },
  setMachinesByArea(state, payload) {
    state.selectedMachinesByArea = payload;
  },
};

const actions = {
  async getMachines({ commit }) {
    const query = {
      expand: ['area', 'productionOrder'],
    };

    try {
      const machines = await getMachines(query);

      commit('setMachines', machines);
    } catch (error) {
      console.log(error, 'error obteniendo areas');
    }
  },
  async getMachinesByAreaId({ commit }, payload) {
    try {
      const machines = await kyInstance.post('machines', { json: payload }).json();
      commit('setMachinesByArea', machines);
    } catch (error) {
      console.log(error, 'error obteniendo maquinas');
    }
  },
};

const state = {
  machinesArray: [],
  selectedMachinesByArea: [],
  selectedMachine: null,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
