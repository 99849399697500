/* eslint-disable arrow-body-style */
import convertToURLSearchParams from '@/helpers/urlParams';
import kyInstance from '@/services/Ky';

/**
 * Retrieves suppliers based on the provided query.
 * @param {Object} query - The query parameters for filtering suppliers.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of suppliers.
 */
const getSuppliers = async (query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get('suppliers', {
    searchParams,
  }).json();
};

/**
 * Retrieves a specific supplier by its ID.
 * @param {string} id - The ID of the supplier to retrieve.
 * @param {Object} query - The query parameters for the supplier retrieval.
 * @returns {Promise<Object>} - A promise that resolves to the retrieved supplier object.
 */
const getSupplier = async (id, query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get(`suppliers/${id}`, { searchParams }).json();
};

/**
 * Creates a new supplier.
 * @param {Object} body - The request body containing the supplier data.
 * @returns {Promise<void>} - A promise that resolves when the create request is successful.
 */
const createSupplier = async (body) => {
  await kyInstance.post('suppliers', { json: body });
};

/**
 * Edits an existing supplier.
 * @param {string} id - The ID of the supplier to be edited.
 * @param {Object} body - The request body containing the updated supplier data.
 * @returns {Promise<void>} - A promise that resolves when the edit request is successful.
 */
const editSupplier = async (id, body) => {
  await kyInstance.patch(`suppliers/${id}`, { json: body });
};

/**
 * Deletes a supplier.
 * @param {string} id - The ID of the supplier to be deleted.
 * @returns {Promise<void>} - A promise that resolves when the delete request is successful.
 */
const deleteSupplier = async (id) => {
  await kyInstance.delete(`suppliers/${id}`);
};

export {
  getSuppliers,
  getSupplier,
  createSupplier,
  editSupplier,
  deleteSupplier,
};
