<template>
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 0.5H2C0.897 0.5 0 1.397 0 2.5V16.5C0 17.603 0.897 18.5 2 18.5H18C19.103
        18.5 20 17.603 20 16.5V2.5C20 1.397 19.103 0.5 18 0.5ZM1.99999 16.5V2.49999H18L18.002
        16.5H1.99999ZM16 4.50001H4V6.50001H16V4.50001ZM16 8.50002H3.99997V10.5H16V8.50002ZM3.99997
        12.5H9.99997V14.5H3.99997V12.5Z"
      :class="props.active ? 'active' : 'not-active'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 0.5H2C0.897 0.5 0 1.397 0 2.5V16.5C0 17.603 0.897 18.5 2 18.5H18C19.103 18.5 20 17.603
        20 16.5V2.5C20 1.397 19.103 0.5 18 0.5ZM1.99999 16.5V2.49999H18L18.002 16.5H1.99999ZM16
        4.50001H4V6.50001H16V4.50001ZM16 8.50002H3.99997V10.5H16V8.50002ZM3.99997
        12.5H9.99997V14.5H3.99997V12.5Z"
      :class="props.active ? 'active' : 'not-active'"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  color: String,
  active: Boolean,
});
</script>

<style lang="scss" scoped>
.active{
  fill: $primary;
}
.not-active{
  fill: $text-a;
}
</style>
