/* eslint-disable arrow-body-style */
import convertToURLSearchParams from '@/helpers/urlParams';
import kyInstance from '@/services/Ky';

/**
 * Retrieves raw materials based on the provided query.
 * @param {Object} query - The query parameters for filtering raw materials.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of raw materials.
 */
const getRawMaterials = async (query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get('raw-materials', {
    searchParams,
  }).json();
};

/**
 * Retrieves a specific raw material by its ID.
 * @param {string} id - The ID of the raw material to retrieve.
 * @param {Object} query - The query parameters for the raw material retrieval.
 * @returns {Promise<Object>} - A promise that resolves to the retrieved raw material object.
 */
const getRawMaterial = async (id, query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get(`raw-materials/${id}`, { searchParams }).json();
};

/**
 * Creates a new raw material.
 * @param {Object} body - The request body containing the raw material data.
 * @returns {Promise<void>} - A promise that resolves when the create request is successful.
 */
const createRawMaterial = async (body) => {
  await kyInstance.post('raw-materials', { json: body });
};

/**
 * Edits an existing raw material.
 * @param {string} id - The ID of the raw material to be edited.
 * @param {Object} body - The request body containing the updated raw material data.
 * @returns {Promise<void>} - A promise that resolves when the edit request is successful.
 */
const editRawMaterial = async (id, body) => {
  await kyInstance.patch(`raw-materials/${id}`, { json: body });
};

/**
 * Deletes a raw material.
 * @param {string} id - The ID of the raw material to be deleted.
 * @returns {Promise<void>} - A promise that resolves when the delete request is successful.
 */
const deleteRawMaterial = async (id) => {
  await kyInstance.delete(`raw-materials/${id}`);
};

export {
  getRawMaterials,
  getRawMaterial,
  createRawMaterial,
  editRawMaterial,
  deleteRawMaterial,
};
