/* eslint-disable arrow-body-style */
import convertToURLSearchParams from '@/helpers/urlParams';
import kyInstance from '@/services/Ky';

/**
 * Retrieves machines based on the provided query.
 * @param {Object} query - The query parameters for filtering machines.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of machines.
 */
const getMachines = async (query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get('machines', {
    searchParams,
  }).json();
};

/**
 * Retrieves a specific machine by its ID.
 * @param {string} id - The ID of the machine to retrieve.
 * @param {Object} query - The query parameters for the machine retrieval.
 * @returns {Promise<Object>} - A promise that resolves to the retrieved machine object.
 */
const getMachine = async (id, query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get(`machines/${id}`, { searchParams }).json();
};

/**
 * Creates a new machine.
 * @param {Object} body - The request body containing the machine data.
 * @returns {Promise<void>} - A promise that resolves when the machine is created successfully.
 */
const createMachine = async (body) => {
  await kyInstance.post('machines', { json: body });
};

/**
 * Edits an existing machine.
 * @param {string} id - The ID of the machine to be edited.
 * @param {Object} body - The request body containing the updated machine data.
 * @returns {Promise<void>} - A promise that resolves when the machine is edited successfully.
 */
const editMachine = async (id, body) => {
  await kyInstance.patch(`machines/${id}`, { json: body });
};

/**
 * Deletes a machine.
 * @param {string} id - The ID of the machine to be deleted.
 * @returns {Promise<void>} - A promise that resolves when the machine is deleted successfully.
 */
const deleteMachine = async (id) => {
  await kyInstance.delete(`machines/${id}`);
};

export {
  getMachines,
  getMachine,
  createMachine,
  editMachine,
  deleteMachine,
};
