import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/Test.vue'),
  },
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword.vue'),
  },
  {
    path: '/new-password',
    name: 'NewPassword',
    component: () => import('../views/NewPassword.vue'),
  },
  {
    path: '/settings',
    name: 'SettingsView',
    component: () => import('../views/SettingsView.vue'),
    children: [
      {
        path: 'usuarios',
        name: 'UsersTabPanel',
        component: () => import('@/components/TabPanel.vue'),
        children: [
          {
            path: '',
            name: 'UsersList',
            component: () => import('@/components/settings/UsersList.vue'),
          },
          {
            path: 'form',
            name: 'UsersForm',
            component: () => import('@/components/settings/UsersForm.vue'),
          },
        ],
      },
      {
        path: 'usuarios-escaner',
        name: 'CardReaderUsersTabPanel',
        component: () => import('@/components/TabPanel.vue'),
        children: [
          {
            path: '',
            name: 'CardReaderUsersList',
            component: () => import('@/components/settings/CardReaderUsersList.vue'),
          },
          {
            path: 'form',
            name: 'CardReaderUsersForm',
            component: () => import('@/components/settings/CardReaderUsersForm.vue'),
          },
        ],
      },
      {
        path: 'permisos',
        name: 'PermissionsTabPanel',
        component: () => import('@/components/TabPanel.vue'),
        children: [
          {
            path: '',
            name: 'PermissionsList',
            component: () => import('@/components/settings/PermissionsList.vue'),
          },
          {
            path: 'form',
            name: 'PermissionsForm',
            component: () => import('@/components/settings/PermissionsForm.vue'),
          },
        ],
      },
      {
        path: 'areas',
        name: 'AreasTabPanel',
        component: () => import('@/components/TabPanel.vue'),
        children: [
          {
            path: '',
            name: 'AreasList',
            component: () => import('@/components/settings/AreasList.vue'),
          },
          {
            path: 'form',
            name: 'AreasForm',
            component: () => import('@/components/settings/AreasForm.vue'),
          },
        ],
      },
      {
        path: 'maquinas',
        name: 'MachineryTabPanel',
        component: () => import('@/components/TabPanel.vue'),
        children: [
          {
            path: '',
            name: 'MachineryList',
            component: () => import('@/components/settings/MachineryList.vue'),
          },
          {
            path: 'form',
            name: 'MachineryForm',
            component: () => import('@/components/settings/MachineryForm.vue'),
          },
        ],
      },
      {
        path: 'clients',
        name: 'ClientsTabPanel',
        component: () => import('@/components/TabPanel.vue'),
        children: [
          {
            path: '',
            name: 'ClientsList',
            component: () => import('@/components/settings/ClientsList.vue'),
          },
          {
            path: 'form',
            name: 'ClientsForm',
            component: () => import('@/components/settings/ClientsForm.vue'),
          },
        ],
      },
      {
        path: 'warehouse-locations',
        name: 'WarehouseLocationsTabPanel',
        component: () => import('@/components/WarehouseLocationsTabPanel.vue'),
        children: [
          {
            path: '',
            name: 'WarehouseLocationsList',
            component: () => import('@/components/settings/WarehouseLocationList.vue'),
          },
          {
            path: 'form',
            name: 'WarehouseLocationsForm',
            component: () => import('@/components/settings/WarehouseLocationForm.vue'),
          },
        ],
      },
      {
        path: 'suppliers',
        name: 'SuppliersTabPanel',
        component: () => import('@/components/SuppliersTabPanel.vue'),
        children: [
          {
            path: '',
            name: 'SuppliersList',
            component: () => import('@/components/settings/SuppliersList.vue'),
          },
          {
            path: 'form',
            name: 'SuppliersForm',
            component: () => import('@/components/settings/SuppliersForm.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/production-orders',
    name: 'ProductionOrdersView',
    component: () => import('@/views/ProductionOrdersView.vue'),
  },
  {
    path: '/orders-history',
    name: 'ProductionOrdersHistoryView',
    component: () => import('../views/ProductionOrdersHistoryView.vue'),
  },
  {
    path: '/production-register',
    name: 'ProductionRegisterView',
    component: () => import('../views/ProductionRegisterView.vue'),
  },
  {
    path: '/inventory',
    name: 'InventoryView',
    component: () => import('../views/InventoryView.vue'),
  },
  {
    path: '/raw-materials-deliveries',
    name: 'RawMaterialsDeliveriesView',
    component: () => import('@/views/RawMaterialsDeliveriesView.vue'),
  },
  {
    path: '/products',
    name: 'ProductsView',
    component: () => import('../views/ProductsView.vue'),
  },
  {
    path: '/new-color',
    name: 'NewColor',
    component: () => import('../views/NewColor.vue'),
  },
  {
    path: '/colors',
    name: 'ColorsView',
    component: () => import('../views/ColorsView.vue'),
  },
  {
    path: '/new-material',
    name: 'NewMaterial',
    component: () => import('../views/NewMaterial.vue'),
  },
  {
    path: '/materials',
    name: 'MaterialsView',
    component: () => import('../views/MaterialsView.vue'),
  },
  {
    path: '/new-raw-material',
    name: 'NewRawMaterial',
    component: () => import('@/views/NewRawMaterial.vue'),
  },
  {
    path: '/raw-materials',
    name: 'RawMaterialsView',
    component: () => import('@/views/RawMaterialsView.vue'),
  },
  {
    path: '/new-order',
    name: 'NewOrder',
    component: () => import('../views/NewOrder.vue'),
  },
  {
    path: '/new-raw-material-delivery',
    name: 'NewRawMateriaDelivery',
    component: () => import('@/views/NewRawMateriaDelivery.vue'),
  },
  {
    path: '/raw-material-delivery/:delivery',
    name: 'EditRawMateriaDelivery',
    component: () => import('@/views/NewRawMateriaDelivery.vue'),
  },
  {
    path: '/raw-material-delivery/:id/items',
    name: 'MaterialDeliveriesItemsView',
    component: () => import('@/views/MaterialDeliveriesItemsView.vue'),
  },
  {
    path: '/order',
    name: 'ViewOrder',
    component: () => import('../views/ViewOrder.vue'),
  },
  {
    path: '/production-orders/:id',
    name: 'ProductionOrder',
    component: () => import('../views/ViewOrder.vue'),
  },
  {
    path: '/order-register',
    name: 'ViewOrderRegister',
    component: () => import('../views/ViewOrderRegister.vue'),
  },
  {
    path: '/new-product',
    name: 'NewProduct',
    component: () => import('../views/NewProduct.vue'),
  },
  {
    path: '/product',
    name: 'ViewProduct',
    component: () => import('../views/ViewProduct.vue'),
  },
  {
    path: '/report',
    name: 'DailyReport',
    component: () => import('../views/DailyReport.vue'),
  },
  {
    path: '/product-label-printer',
    component: () => import('@/views/labelprinter/PrinterWrapper.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/labelprinter/CardReader.vue'),
      },
      {
        path: 'area-selection',
        component: () => import('@/views/labelprinter/AreaSelection.vue'),
      },
      {
        path: 'machine-selection',
        component: () => import('@/views/labelprinter/MachineSelection.vue'),
      },
      {
        path: 'order-review',
        component: () => import('@/views/labelprinter/OrderReviewPackage.vue'),
      },
      {
        path: 'review-print',
        component: () => import('@/views/labelprinter/ReviewPrint.vue'),
      },
      {
        path: 'tutorial',
        component: () => import('@/views/labelprinter/Tutorial.vue'),
      },
    ],
  },
  {
    path: '/product-pallet-label-printer',
    component: () => import('@/views/labelprinter/PrinterWrapper.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/labelprinter/CardReader.vue'),
      },
      {
        path: 'area-selection',
        component: () => import('@/views/labelprinter/AreaSelection.vue'),
      },
      {
        path: 'machine-selection',
        component: () => import('@/views/labelprinter/MachineSelection.vue'),
      },
      {
        path: 'order-review',
        component: () => import('@/views/labelprinter/OrderReviewPallet.vue'),
      },
      {
        path: 'review-print',
        component: () => import('@/views/labelprinter/ReviewPrint.vue'),
      },
      {
        path: 'tutorial',
        component: () => import('@/views/labelprinter/Tutorial.vue'),
      },
    ],
  },
  {
    path: '/material-pallet-label-printer',
    component: () => import('@/views/labelprinter/PrinterWrapper.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/labelprinter/CardReader.vue'),
      },
      {
        path: 'delivery-selection',
        component: () => import('@/views/labelprinter/MaterialDeliverySelection.vue'),
      },
      {
        path: 'item-delivery-selection',
        component: () => import('@/views/labelprinter/MaterialDeliveryItemSelection.vue'),
      },
      {
        path: 'delivery-review',
        component: () => import('@/views/labelprinter/DeliveryReviewPallet.vue'),
      },
      {
        path: 'review-print',
        component: () => import('@/views/labelprinter/ReviewPrint.vue'),
      },
      {
        path: 'tutorial',
        component: () => import('@/views/labelprinter/Tutorial.vue'),
      },
    ],
  },
  {
    path: '/production-dashboard',
    name: 'ProductionDashboard',
    component: () => import('@/views/ProductionDashboard.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
