import kyInstance from '@/services/Ky';

function returnFormattedResults(totalProductionBetweenDates, totalProductionToEndDate) {
  const mergedData = [];

  totalProductionBetweenDates.forEach((item1) => {
    const item2 = totalProductionToEndDate.find((item) => item.order_id === item1.order_id);

    if (item2) {
      mergedData.push({
        id: item1.production_order.id,
        userSetOrderId: item1.production_order.user_set_id,
        productName: item1.production_order.product.name,
        area: item1.production_order.product.area.name,
        totalPieces: parseInt(item2.pieces, 10),
        piecesToProduce: item1.production_order.qty_pieces_to_produce,
        leftPieces: item1.production_order.qty_pieces_to_produce - item2.pieces,
        periodPieces: parseInt(item1.pieces, 10),
      });
    }
  });

  return mergedData;
}

function sortingFunction(a, b) {
  if (a.area < b.area) {
    return -1;
  }
  if (a.area > b.area) {
    return 1;
  }
  return 0;
}

const mutations = {
  setReportResults(state, payload) {
    state.reportResults = payload;
  },
  setCurrentShiftProduction(state, payload) {
    state.currentShiftProduction = payload;
  },
};

const actions = {
  async getCurrentShiftProduction({ commit }, { currentDate, areaId }) {
    try {
      let searchParams = {
        current_date: currentDate,
      };
      if (areaId) {
        searchParams = {
          ...searchParams,
          area_id: areaId,
        };
      }
      const shiftProduction = await kyInstance.get('report/current-shift', {
        searchParams,
      }).json();

      console.log(shiftProduction, 'shiftProduction');

      commit('setCurrentShiftProduction', shiftProduction);
    } catch (error) {
      console.log(error, 'error obteniendo reporte');
    }
  },
  async getReport({ commit }, { initialDate, endDate }) {
    try {
      const response = await kyInstance.get('report', {
        searchParams: {
          initialDate,
          endDate,
        },
      }).json();

      commit('setReportResults', response);
    } catch (error) {
      console.log(error, 'error obteniendo reporte');
    }
  },
};

const getters = {
  getFormattedReportResults({ reportResults }) {
    const { totalProductionToEndDate, totalProductionBetweenDates } = reportResults;

    const formattedResults = returnFormattedResults(totalProductionBetweenDates, totalProductionToEndDate);

    formattedResults.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      item.totalPieces = item.totalPieces.toLocaleString('es-MX');
      // eslint-disable-next-line no-param-reassign
      item.piecesToProduce = item.piecesToProduce.toLocaleString('es-MX');
      // eslint-disable-next-line no-param-reassign
      item.leftPieces = item.leftPieces.toLocaleString('es-MX');
      // eslint-disable-next-line no-param-reassign
      item.periodPieces = item.periodPieces.toLocaleString('es-MX');
    });

    formattedResults.sort(sortingFunction);

    return {
      total: formattedResults,
    };
  },
};

const state = {
  reportResults: {},
  currentShiftProduction: {},
};

export default {
  mutations,
  actions,
  getters,
  state,
};
