<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.4654 4.17758L9.3654 0.577624C9.1314 0.474125 8.8677 0.474125 8.6337
      0.577624L0.5337 4.17758C0.5157 4.18568 0.5031 4.19918 0.4851 4.20908C0.4599
      4.22168 0.4329 4.22978 0.4095 4.24508C0.3897 4.25858 0.3744 4.27568 0.3555
      4.29008C0.3303 4.30898 0.306 4.32968 0.2835 4.35128C0.2466 4.38638 0.2142
      4.42418 0.1845 4.46468C0.1665 4.48988 0.1476 4.51237 0.1314 4.53757C0.1035
      4.58527 0.0828 4.63477 0.063 4.68607C0.0549 4.71037 0.0423 4.73287 0.0351
      4.75717C0.0135 4.83547 0 4.91647 0 5.00017V14.0001C0 14.3565 0.2088 14.6777
      0.5346 14.8226L8.6346 18.4226C8.7516 18.4748 8.8758 18.5 9 18.5C9.1251
      18.5 9.2457 18.4649 9.3618 18.4136L9.3654 18.4226L17.4654 14.8226C17.7912
      14.6777 18 14.3565 18 14.0001V5.00017C18 4.64377 17.7912 4.32248 17.4654
      4.17758ZM9 2.3857L14.8842 5.00017L9 7.61464L7.8228 7.09174L3.1167 5.00017L9
      2.3857ZM1.8 13.416V6.38525L8.1 9.18512V16.2149L1.8 13.416ZM9.9 16.2149V9.18512L16.2
      6.38525V13.416L9.9 16.2149Z"
      :class="props.active ? 'active' : 'not-active'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.4654 4.17758L9.3654 0.577624C9.1314 0.474125 8.8677 0.474125 8.6337
      0.577624L0.5337 4.17758C0.5157 4.18568 0.5031 4.19918 0.4851 4.20908C0.4599
      4.22168 0.4329 4.22978 0.4095 4.24508C0.3897 4.25858 0.3744 4.27568 0.3555
      4.29008C0.3303 4.30898 0.306 4.32968 0.2835 4.35128C0.2466 4.38638 0.2142
      4.42418 0.1845 4.46468C0.1665 4.48988 0.1476 4.51237 0.1314 4.53757C0.1035
      4.58527 0.0828 4.63477 0.063 4.68607C0.0549 4.71037 0.0423 4.73287 0.0351
      4.75717C0.0135 4.83547 0 4.91647 0 5.00017V14.0001C0 14.3565 0.2088 14.6777
      0.5346 14.8226L8.6346 18.4226C8.7516 18.4748 8.8758 18.5 9 18.5C9.1251 18.5
      9.2457 18.4649 9.3618 18.4136L9.3654 18.4226L17.4654 14.8226C17.7912 14.6777
      18 14.3565 18 14.0001V5.00017C18 4.64377 17.7912 4.32248 17.4654 4.17758ZM9
      2.3857L14.8842 5.00017L9 7.61464L7.8228 7.09174L3.1167 5.00017L9 2.3857ZM1.8
      13.416V6.38525L8.1 9.18512V16.2149L1.8 13.416ZM9.9 16.2149V9.18512L16.2
      6.38525V13.416L9.9 16.2149Z"
      :class="props.active ? 'active' : 'not-active'"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  color: String,
  active: Boolean,
});
</script>

<style lang="scss" scoped>
.active{
  fill: $primary;
}
.not-active{
  fill: $text-a;
}
</style>
