<template>
  <q-header reveal class="bg-none text-black"
    v-if="$route.fullPath != '/login' && $route.fullPath != '/forgot-password'
      && $route.fullPath != '/new-password'">
    <q-toolbar style="min-height: 90px; background-color: #FFF;">
      <q-img v-if="props.cardreader" src="@/assets/logo.svg" height="auto" width="160px"></q-img>
      <q-btn v-if="!props.cardreader" dense flat round icon="menu" @click="toggleLeftDrawer" />
      <q-toolbar-title>
        <!-- <span class="title">Inicio</span> -->
        <BaseBreadcrumbs :title="props.title" :subtitle="props.subtitle"></BaseBreadcrumbs>
      </q-toolbar-title>
      <div class="settings" @click="openSettings">
        <q-icon name="settings" color="grey-13" size="32px" />
        <span>Configuración</span>
      </div>
      <q-avatar size="50px" color="primary" text-color="white">{{ user?.name?.charAt(0) }}</q-avatar>
    </q-toolbar>
  </q-header>
  <Drawer v-if="!props.cardreader" v-model="leftDrawerOpen"></Drawer>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import Drawer from '@/components/Drawer.vue';
import BaseBreadcrumbs from '@/components/base/BaseBreadcrumbs.vue';
import { mapState } from '@/helpers/stateLib';

const router = useRouter();
const { user } = mapState('auth');

const leftDrawerOpen = ref(false);
const toggleLeftDrawer = () => {
  leftDrawerOpen.value = !leftDrawerOpen.value;
};

const props = defineProps({
  title: String,
  subtitle: String,
  cardreader: Boolean,
});

const openSettings = () => {
  router.push({ path: '/settings/usuarios' });
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 28px;
  font-weight: 600;
  color: $title-text;
  font-family: 'Jost', sans-serif;
}
.settings{
  margin-right: 30px;
  cursor: pointer;
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #7E7887;
}
@media only screen and (max-width: 600px) {
  .settings span{
    display: none;
  }
}
</style>
