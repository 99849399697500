/* eslint-disable arrow-body-style */
import convertToURLSearchParams from '@/helpers/urlParams';
import kyInstance from '@/services/Ky';

/**
 * Retrieves roles based on the provided query.
 * @param {Object} query - The query parameters for filtering roles.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of roles.
 */
const getRoles = async (query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get('roles', {
    searchParams,
  }).json();
};

/**
 * Retrieves a specific role by its ID.
 * @param {string} id - The ID of the role to retrieve.
 * @param {Object} query - The query parameters for the role retrieval.
 * @returns {Promise<Object>} - A promise that resolves to the retrieved role object.
 */
const getRole = async (id, query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get(`roles/${id}`, { searchParams }).json();
};

/**
 * Creates a new role.
 * @param {Object} body - The request body containing the role data.
 * @returns {Promise<void>} - A promise that resolves when the create request is successful.
 */
const createRole = async (body) => {
  await kyInstance.post('roles', { json: body });
};

/**
 * Edits an existing role.
 * @param {string} id - The ID of the role to be edited.
 * @param {Object} body - The request body containing the updated role data.
 * @returns {Promise<void>} - A promise that resolves when the edit request is successful.
 */
const editRole = async (id, body) => {
  await kyInstance.patch(`roles/${id}`, { json: body });
};

/**
 * Deletes a role.
 * @param {string} id - The ID of the role to be deleted.
 * @returns {Promise<void>} - A promise that resolves when the delete request is successful.
 */
const deleteRole = async (id) => {
  await kyInstance.delete(`roles/${id}`);
};

export {
  getRoles,
  getRole,
  createRole,
  editRole,
  deleteRole,
};
