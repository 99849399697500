<template>
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.999987 11.4999H1.99997V13.4999V18.4998C1.99997
        19.6027 2.89695 20.4997 3.99993 20.4997H6.99987H12.9998H15.9997C17.1027
        20.4997 17.9997 19.6027 17.9997 18.4998V13.4999V11.4999H18.9996C19.4036
        11.4999 19.7696 11.2559 19.9236 10.8829C20.0786 10.5089 19.9926 10.0789
        19.7066 9.79292L10.7068 0.793092C10.3158 0.402099 9.68382 0.402099 9.29283
        0.793092L0.293001 9.79292C0.00700601 10.0789 -0.0789924 10.5089 0.0760047
        10.8829C0.230002 11.2559 0.595995 11.4999 0.999987 11.4999ZM7.99986
        18.4997V13.4998H11.9998V18.4997H7.99986ZM9.99978 2.91402L15.9997 8.91391V13.4998L16.0007
        18.4997H13.9997V13.4998C13.9997 12.3968 13.1027 11.4999 11.9997 11.4999H7.99982C6.89684
        11.4999 5.99986 12.3968 5.99986
        13.4998V18.4997H3.9999V13.4998V10.4999V8.91391L9.99978 2.91402Z"
      :class="props.active ? 'active' : 'not-active'"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  color: String,
  active: Boolean,
});
</script>

<style lang="scss" scoped>
.active{
  fill: $primary;
}
.not-active{
  fill: $text-a;
}
</style>
