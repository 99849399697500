import {
  getUsers,
} from '@/api/users';
import {
  getCardReaderUsers,
  getCardReaderUser,
  createCardReaderUser,
  editCardReaderUser,
  deleteCardReaderUser,
} from '@/api/card_reader_users';

const mutations = {
  setUsers(state, payload) {
    state.users = payload;
  },
  setCardReaderUsers(state, payload) {
    state.cardreaderUsers = payload;
  },
};

const actions = {
  async getUsers({ commit }) {
    try {
      const users = await getUsers();
      commit('setUsers', users);
    } catch (error) {
      console.log(error, 'error obteniendo usuarios');
    }
  },
  async getCardReaderUsers({ commit }) {
    try {
      const users = await getCardReaderUsers();
      commit('setCardReaderUsers', users);
    } catch (error) {
      console.log(error, 'error obteniendo usuarios');
    }
  },
  async getCardReaderUser(_, id) {
    try {
      const user = await getCardReaderUser(id);
      return user;
    } catch (error) {
      console.log(error, 'error obteniendo usuario');
      return null;
    }
  },
  async createCardReaderUser({ dispatch }, payload) {
    try {
      await createCardReaderUser(payload);
      await dispatch(
        'createNotification',
        { msg: 'Usuario de Escaner Creado', type: 'positive' },
        { root: true },
      );
      await dispatch('getCardReaderUsers');
    } catch (error) {
      await dispatch(
        'createNotification',
        { msg: 'Error creando Usuario de Escaner', type: 'negative' },
        { root: true },
      );
      throw error;
    }
  },
  async editCardReaderUser({ dispatch }, payload) {
    try {
      await editCardReaderUser(payload.id, payload);
      await dispatch(
        'createNotification',
        { msg: 'Usuario de Escaner Editada', type: 'positive' },
        { root: true },
      );
      await dispatch('getCardReaderUsers');
    } catch (error) {
      await dispatch(
        'createNotification',
        { msg: 'Error editando Usuario de Escaner', type: 'negative' },
        { root: true },
      );
    }
  },
  async deleteCardReaderUser({ dispatch }, payload) {
    try {
      await deleteCardReaderUser(payload);
      await dispatch(
        'createNotification',
        { msg: 'Usuario de Escaner Eliminada', type: 'negative' },
        { root: true },
      );
      await dispatch('getCardReaderUsers');
    } catch (error) {
      await dispatch(
        'createNotification',
        { msg: 'Error eliminando Usuario de Escaner', type: 'negative' },
        { root: true },
      );
    }
  },
};

const state = {
  users: [],
  cardreaderUsers: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
