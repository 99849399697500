import { getColors } from '@/api/colors';

const mutations = {
  setColors(state, payload) {
    state.colors = payload;
  },
  setLoadingState(state, payload) {
    state.isLoading = payload;
  },
};

const actions = {
  async getColors({ commit }, query) {
    commit('setLoadingState', true);
    try {
      const colors = await getColors(query);
      commit('setColors', colors);
    } catch (error) {
      console.log(error, 'error obteniendo productos');
    } finally {
      commit('setLoadingState', false);
    }
  },
};

const state = {
  colors: [],
  isLoading: false,
};

export default {
  namespaced: true,
  mutations,
  actions,
  state,
};
