/* eslint-disable arrow-body-style */
import convertToURLSearchParams from '@/helpers/urlParams';
import kyInstance from '@/services/Ky';

/**
 * Retrieves warehouse locations based on the provided query.
 * @param {Object} query - The query parameters for filtering warehouse locations.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of warehouse locations.
 */
const getWarehouseLocations = async (query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get('warehouse-locations', {
    searchParams,
  }).json();
};

/**
 * Retrieves a specific warehouse location by ID.
 * @param {string} id - The ID of the warehouse location to retrieve.
 * @param {Object} query - The query parameters for additional filtering.
 * @returns {Promise<Object>} - A promise that resolves to the warehouse location object.
 */
const getWarehouseLocation = async (id, query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get(`warehouse-locations/${id}`, { searchParams }).json();
};

/**
 * Creates a new warehouse location.
 * @param {Object} body - The request body containing the warehouse location data.
 * @returns {Promise<void>} - A promise that resolves when the create request is successful.
 */
const createWarehouseLocation = async (body) => {
  await kyInstance.post('warehouse-locations', { json: body });
};

/**
 * Edits an existing warehouse location.
 * @param {string} id - The ID of the warehouse location to be edited.
 * @param {Object} body - The request body containing the updated warehouse location data.
 * @returns {Promise<void>} - A promise that resolves when the edit request is successful.
 */
const editWarehouseLocation = async (id, body) => {
  await kyInstance.patch(`warehouse-locations/${id}`, { json: body });
};

/**
 * Deletes a warehouse location.
 * @param {string} id - The ID of the warehouse location to be deleted.
 * @returns {Promise<void>} - A promise that resolves when the delete request is successful.
 */
const deleteWarehouseLocation = async (id) => {
  await kyInstance.delete(`warehouse-locations/${id}`);
};

export {
  getWarehouseLocations,
  getWarehouseLocation,
  createWarehouseLocation,
  editWarehouseLocation,
  deleteWarehouseLocation,
};
