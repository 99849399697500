/* eslint-disable no-param-reassign */
import {
  getClients,
} from '@/api/clients';

const mutations = {
  setClients(state, payload) {
    state.clients = payload;
  },
};

const actions = {
  async getClients({ commit }) {
    try {
      const clients = await getClients();
      commit('setClients', clients);
    } catch (error) {
      console.log(error, 'error obteniendo clientes');
    }
  },
};

const state = {
  clients: {},
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
