import kyInstance from '@/services/Ky';

const mutations = {
  setPackages(state, payload) {
    state.packagesArray = payload;
  },
  setReviewedPackages(state, payload) {
    state.reviewedPackagesArray = payload;
  },
};

const actions = {
  async getLastPackagesByOrderId({ commit }, payload) {
    try {
      const searchParams = new URLSearchParams();
      searchParams.append('order_id', payload.order_id);
      searchParams.append('limit', payload.limit);
      searchParams.append('expand[]', 'productInspections');

      const pkgs = await kyInstance.get('packages', { searchParams }).json();
      commit('setPackages', pkgs);
      return pkgs;
    } catch (error) {
      console.log(error, 'error obteniendo productos');
      return 0;
    }
  },
  async getReviewedPackagesByOrderId({ commit }, payload) {
    try {
      const searchParams = new URLSearchParams();
      searchParams.append('order_id', payload.order_id);
      searchParams.append('expand[]', 'productInspections');
      searchParams.append('reviewed', true);

      const pkgs = await kyInstance.get('packages', { searchParams }).json();
      commit('setReviewedPackages', pkgs);
      return pkgs;
    } catch (error) {
      console.log(error, 'error obteniendo productos');
      return 0;
    }
  },
  async reviewPackage(commit, payload) {
    await kyInstance.post('package/review', { json: payload });
  },
  async approvePackage(commit, payload) {
    await kyInstance.post('package/approve', { json: payload }).json();
  },
};

const getters = {
  approvedPackages(state) {
    return state.reviewedPackagesArray.filter((pkg) => pkg.is_approved);
  },
  rejectedPackages(state) {
    return state.reviewedPackagesArray.filter((pkg) => !pkg.is_approved);
  },
};

const state = {
  packagesArray: [],
  reviewedPackagesArray: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
