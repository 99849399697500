import kyInstance from '@/services/Ky';

const mutations = {
  setProducts(state, payload) {
    state.productsArray = payload;
  },
  setSelectedProduct(state, payload) {
    state.selectedProduct = payload;
  },
  addProduct(state, payload) {
    state.productsArray.push(payload);
  },
  setColors(state, payload) {
    state.colors = payload;
  },
  setSelectedColor(state, payload) {
    state.selectedColor = payload;
  },
  setMaterials(state, payload) {
    state.materials = payload;
  },
  setSelectedMaterial(state, payload) {
    state.selectedMaterial = payload;
  },
  setInventory(state, payload) {
    state.inventory = payload;
  },
};

const actions = {
  async getProducts({ commit }, payload) {
    const searchParams = new URLSearchParams();

    if (payload && payload.pagination) {
      searchParams.append('page', payload.pagination.page);
      searchParams.append('limit', payload.pagination.rowsPerPage);
    }

    if (payload && payload.filter) {
      searchParams.append('name', payload.filter);
    }

    searchParams.append('expand[]', 'material');
    searchParams.append('expand[]', 'color');

    try {
      const response = await kyInstance.get('products', {
        searchParams,
      }).json();

      if (payload) {
        const { data: products, meta } = response;
        commit('setProducts', products);
        return meta;
      }

      commit('setProducts', response);
      return null;
    } catch (error) {
      // TODO: Send error message to the user
      console.log(error, 'error obteniendo productos');
      return null;
    }
  },
  async getProductById({ commit }, payload) {
    const searchParams = new URLSearchParams();
    searchParams.append('expand[]', 'material');
    searchParams.append('expand[]', 'area');
    searchParams.append('expand[]', 'color');
    searchParams.append('expand[]', 'image');

    try {
      const product = await kyInstance.get(`products/${payload.id}`, {
        searchParams,
      }).json();

      commit('setSelectedProduct', product);
      return product;
    } catch (error) {
      console.log(error, 'error obteniendo productos');
      return 0;
    }
  },
  async getMaterials({ commit }) {
    try {
      const materials = await kyInstance.get('materials').json();
      commit('setMaterials', materials);
      return materials;
    } catch (error) {
      console.log(error, 'error obteniendo productos');
      return 0;
    }
  },
  async getMaterialById({ commit }, payload) {
    const material = await kyInstance.get(`materials/${payload}`).json();
    commit('setSelectedMaterial', material);
  },
  async getColors({ commit }) {
    try {
      const colors = await kyInstance.get('colors').json();
      commit('setColors', colors);
      return colors;
    } catch (error) {
      console.log(error, 'error obteniendo productos');
      return 0;
    }
  },
  async getColorById({ commit }, payload) {
    const color = await kyInstance.get(`colors/${payload}`).json();
    commit('setSelectedColor', color);
  },
  async createProduct({ dispatch }, payload) {
    await kyInstance.post('products', { body: payload });
    await dispatch(
      'createNotification',
      { msg: 'Producto Creado', type: 'positive' },
      { root: true },
    );
  },
  async editProduct({ dispatch }, payload) {
    const id = payload.get('id');
    // eslint-disable-next-line no-restricted-syntax
    await kyInstance.patch(`products/${id}`, { body: payload });
    await dispatch(
      'createNotification',
      { msg: 'Producto Editado', type: 'positive' },
      { root: true },
    );
  },
  async deleteProduct({ dispatch }, payload) {
    try {
      await kyInstance.delete(`product/${payload}`);
      await dispatch(
        'createNotification',
        { msg: 'Producto Eliminado', type: 'positive' },
        { root: true },
      );
      await dispatch('getProducts');
    } catch (error) {
      console.log(error, 'error eliminando producto');
    }
  },
  async createMaterial({ dispatch }, payload) {
    try {
      await kyInstance.post('materials', { json: payload });
      await dispatch(
        'createNotification',
        { msg: 'Material Creado', type: 'positive' },
        { root: true },
      );
      await dispatch('getMaterials');
    } catch (error) {
      await dispatch(
        'createNotification',
        { msg: 'Error creando material', type: 'negative' },
        { root: true },
      );
      throw error;
    }
  },
  async editMaterial({ dispatch }, payload) {
    try {
      await kyInstance.patch(`materials/${payload.id}`, { json: payload });
      await dispatch(
        'createNotification',
        { msg: 'Material Editado', type: 'positive' },
        { root: true },
      );
      await dispatch('getMaterials');
    } catch (error) {
      await dispatch(
        'createNotification',
        { msg: 'Error editando material', type: 'negative' },
        { root: true },
      );
    }
  },
  async deleteMaterial({ dispatch }, payload) {
    try {
      await kyInstance.delete(`materials/${payload}`);
      await dispatch(
        'createNotification',
        { msg: 'Material Eliminado', type: 'positive' },
        { root: true },
      );
      await dispatch('getMaterials');
    } catch (error) {
      await dispatch(
        'createNotification',
        { msg: 'Error eliminando material', type: 'negative' },
        { root: true },
      );
    }
  },
  async createColor({ dispatch }, payload) {
    try {
      await kyInstance.post('colors', { json: payload });
      await dispatch(
        'createNotification',
        { msg: 'Color Creado', type: 'positive' },
        { root: true },
      );
      await dispatch('getColors');
    } catch (error) {
      await dispatch(
        'createNotification',
        { msg: 'Error creando color', type: 'negative' },
        { root: true },
      );
      throw error;
    }
  },
  async editColor({ dispatch }, payload) {
    try {
      await kyInstance.patch(`colors/${payload.id}`, { json: payload });
      await dispatch(
        'createNotification',
        { msg: 'Color Editado', type: 'positive' },
        { root: true },
      );
      await dispatch('getMaterials');
    } catch (error) {
      await dispatch(
        'createNotification',
        { msg: 'Error editando color', type: 'negative' },
        { root: true },
      );
    }
  },
  async deleteColor({ dispatch }, payload) {
    try {
      await kyInstance.delete(`colors/${payload}`);
      await dispatch(
        'createNotification',
        { msg: 'Color Eliminado', type: 'positive' },
        { root: true },
      );
      await dispatch('getColors');
    } catch (error) {
      await dispatch(
        'createNotification',
        { msg: 'Error eliminando color', type: 'negative' },
        { root: true },
      );
    }
  },
  async getInventory({ commit }) {
    try {
      const inventory = await kyInstance.get('inventory').json();
      commit('setInventory', inventory);
      return inventory;
    } catch (error) {
      console.log(error, 'error obteniendo inventario');
      return 0;
    }
  },
};

const getters = {
  colorNames(state) {
    return state.colors.map((a) => a.name);
  },
  materialNames(state) {
    return state.materials.map((a) => a.name);
  },
};

const state = {
  selectedProduct: null,
  productsArray: [],
  selectedColor: null,
  colors: [],
  selectedMaterial: null,
  materials: [],
  inventory: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
