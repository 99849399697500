/* eslint-disable arrow-body-style */
import convertToURLSearchParams from '@/helpers/urlParams';
import kyInstance from '@/services/Ky';

/**
 * Retrieves clients based on the provided query.
 * @param {Object} query - The query parameters for filtering clients.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of clients.
 */
const getClients = async (query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get('clients', {
    searchParams,
  }).json();
};

/**
 * Retrieves a specific client by its ID.
 * @param {string} id - The ID of the client to retrieve.
 * @param {Object} query - The query parameters for the client retrieval.
 * @returns {Promise<Object>} - A promise that resolves to the retrieved client object.
 */
const getClient = async (id, query) => {
  let searchParams;
  if (query) {
    searchParams = convertToURLSearchParams(query);
  }

  return kyInstance.get(`clients/${id}`, { searchParams }).json();
};

/**
 * Creates a new client.
 * @param {Object} body - The request body containing the client data.
 * @returns {Promise<void>} - A promise that resolves when the client is created successfully.
 */
const createClient = async (body) => {
  await kyInstance.post('clients', { json: body });
};

/**
 * Edits an existing client.
 * @param {string} id - The ID of the client to be edited.
 * @param {Object} body - The request body containing the updated client data.
 * @returns {Promise<void>} - A promise that resolves when the client is edited successfully.
 */
const editClient = async (id, body) => {
  await kyInstance.patch(`clients/${id}`, { json: body });
};

/**
 * Deletes a client.
 * @param {string} id - The ID of the client to be deleted.
 * @returns {Promise<void>} - A promise that resolves when the client is deleted successfully.
 */
const deleteClient = async (id) => {
  await kyInstance.delete(`clients/${id}`);
};

export {
  getClients,
  getClient,
  createClient,
  editClient,
  deleteClient,
};
