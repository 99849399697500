<template>
  <q-item clickable v-ripple>
    <q-item-section avatar>
      <q-avatar>
        <q-img width="25px" src="@/assets/production-item.png" alt="" />
        <!-- <q-img :src="props.item.img" alt="" /> -->
      </q-avatar>
    </q-item-section>

    <q-item-section>
      <q-item-label class="name">{{ props.item.name }} <span class="subitem">{{ props.item.order }}</span></q-item-label>
    </q-item-section>

    <q-item-section
      class="pieces"
      side
      v-if="props.item.pieces">
      {{ parseInt(props.item.pieces).toLocaleString('es-MX') }} pz
    </q-item-section>
  </q-item>
</template>

<script setup>
const props = defineProps({
  item: Object,
});

</script>

<style lang="scss" scoped>
.pieces{
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
}
.subitem {
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #9E9E9E;
  margin-left: 20px;
}
@media only screen and (max-width: 700px) {
  .name {
    display: flex;
    flex-direction: column;
  }
  .subitem {
    display: block;
    margin-left: 0;
    margin-top: 5px;
  }
}
</style>
