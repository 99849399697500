import { createApp } from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';;
import { VueQueryPlugin } from '@tanstack/vue-query';
import App from './App.vue';
import router from './router';
import store from './store';
import quasarUserOptions from './quasar-user-options';
import { injectStore } from './services/Ky';

import '@/styles/font-size.min.css';

createApp(App).use(Quasar, quasarUserOptions).use(VueQueryPlugin).use(store)
  .use(router)
  .mount('#app');

injectStore(store);
