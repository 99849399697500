/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-body-style */
// import convertToURLSearchParams from '@/helpers/urlParams';
import kyInstance from '@/services/Ky';

/**
 * Retrieves permissions list
 * @returns {Promise<Object[]>} - A promise that resolves to an array of permissions.
 */
const getPermissionsList = async () => {
  return kyInstance.get('permissions').json();
};

export {
  getPermissionsList,
};
